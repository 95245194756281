<template>
  <b-row>
    <b-col sm="12" xl="8">
      <c-card :title="`Ubah Pemasok '${formData.name}'`" :loading="cardloading">
        <template slot="action">
          <c-button :to="{name:'partners'}" variant="outline-primary">Kembali</c-button>
          <c-button v-if="!cardloading" :loading="isloading" @click="$refs.form.onSubmit()" type="submit">Simpan</c-button>
        </template>
        <c-form ref="form" @submit="confirmSubmit">
          <form-input label="Kode" v-model="formData.code" :rules="{required: true}"></form-input>
          <form-input label="Nama" v-model="formData.name" :rules="{required: true}"></form-input>
          <form-checkbox v-model="formData.is_cash" label="Kas Tunai"></form-checkbox>
          <form-checkbox v-model="formData.is_active" label="Aktif"></form-checkbox>
          <button type="submit" hidden></button>
        </c-form>
      </c-card>
    </b-col>
  </b-row>
</template>

<script>
import CCard from '@/utils/components/CCard.vue'
import FormInput from '@/utils/components/FormInput.vue'
import CForm from '@/utils/components/CForm.vue'
import CButton from '@/utils/components/CButton.vue'
import FormCheckbox from '@/utils/components/FormCheckbox.vue'

export default {
  components: { CCard, FormInput, CForm, CButton, FormCheckbox },
  data(){
    const formData = {
      code:'',
      name:'',
      is_active: true,
      is_cash: true
    }
    return {
      formData,
      isloading: false,
      cardloading: true,
    }
  },
  methods:{
    confirmSubmit(){
      this.confirmation(`submitForm`)
    },
    submitForm(){
      const vm = this
      vm.isloading = true
      const formData = vm.formData
      formData.is_active = formData.is_active ? 1 : 0
      formData.is_cash = formData.is_cash ? 1 : 0
      this.$http.put(`v1/payment_partners/update`,formData,{params:{id:vm.$route.params.id,name:formData.name}}).then(() => {
        vm.notify(`Data Berhasil Disimpan!`)
        vm.$router.push({name:'partners'})
        vm.isloading = false
      }).catch((error) => {
        vm.isloading = false
        vm.handleError(error)
      })
    },
    getData(){
      const vm = this
      vm.cardloading = true
      vm.$http.get(`v1/payment_partners/show`,{params:{id:vm.$route.params.id}}).then(({data}) => {
        vm.formData = {
          code: data.code,
          name: data.name,
          is_active: data.is_active ? true : false,
          is_cash: data.is_cash ? true : false
        }
        vm.cardloading = false
      })
    }
  },
  created(){
    this.getData()
  }

}
</script>

<style>

</style>